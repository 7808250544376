import React from "react";
import {
  IonContent,  
  IonPage
} from "@ionic/react";
import HeaderMenu from "../components/HeaderMenu";

import { BryntumScheduler } from 'bryntum-react-shared';

import 'bryntum-scheduler/scheduler.classic-light.css';

import data from '../dummydata/data.json'; 

const Scheduling: React.FC = () => {
 
  const getResources = () =>{    
      return data[0].resources.rows;
  };

  const getEvents = () =>{  
      return data[0].events.rows;
  };

  const getTimeRanges = () =>{
      return data[0].timeRanges.rows;
  }

  const getContextMenu = () =>{
    const contextMenu = {
        items : {
            switchToDog : {
                text : 'Dog',
                icon : 'b-fa b-fa-fw b-fa-dog',
                weight : 500
            },
            switchToCat : {
                text : 'Cat',
                icon : 'b-fa b-fa-fw b-fa-cat',
                onItem(contextRecord:any) {
                    contextRecord.dog = false;
                    contextRecord.cat = true;
                },
                weight : 510
            }
        }
    }

    return contextMenu;
}

  return (
    <IonPage>
      <HeaderMenu title="Scheduling" />
      <IonContent class="ion-padding">
            <BryntumScheduler 
                resources         = {getResources()}
                events            = {getEvents()}
                startDate         = { new Date(2020,10,27,6) }
                endDate           = { new Date(2020,10,27,20) }
                viewPreset        = "hourAndDay"
                rowHeight         = {50}
                barMargin         = {10}
                multiEventSelect  = {true}
                columns           = { [{ text:"Name", field:"name", width:100 }] }
                mode              = "vertical"
                useInitialAnimation = 'slide-from-top'
                timeRanges        = { getTimeRanges() }
                eventMenuFeature  = { getContextMenu() }
            />
      </IonContent>
    </IonPage>
  );
};

export default Scheduling;
