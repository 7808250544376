import React from "react";
import { Redirect, Route, Switch } from "react-router";
import {
  IonPage,
  IonRouterOutlet,
} from "@ionic/react";

import SignIn from "../pages/SignIn";

const Auth: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Switch>
          <Route path="/auth/signin" component={SignIn} exact />
          <Redirect path="/auth" to="/auth/signin" />
        </Switch>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default Auth;
