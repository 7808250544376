import {
  IonContent,
  IonPage,
} from "@ionic/react";
import React from "react";
import HeaderMenu from "../components/HeaderMenu";

const Dashboard: React.FC = () => {
  return (
    <IonPage>
      <HeaderMenu title="Dashboard" />
      <IonContent class="ion-padding">
        <h1>Dashboard page</h1>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
