import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";
import {
  IonPage,
  IonRouterOutlet,
  IonMenu,
  IonHeader,
  IonToolbar,
  IonList,
  IonItem,
  IonContent,
  IonIcon,
  IonLabel,
  IonMenuToggle,
  IonSplitPane,
  IonImg,
} from "@ionic/react";
import {
  barChartOutline,
  calendarOutline,
  documentsOutline,  
  folderOpenOutline,
  pulseOutline,
} from "ionicons/icons";

import Dashboard from "../pages/Dashboard";
import Scheduling from "../pages/Sheduling";
import PatientRegistration from "../pages/PatientRegistration";
import ClinicalInformation from "../pages/ClinicalInformation";
import PatientDocuments from "../pages/PatientDocuments";
import About from "../pages/About";
import SearchPatient from "../pages/SearchPatient";

import './Admin.css';

const Admin: React.FC = () => {
  const location = useLocation();
    
  return (
    <IonPage>
      <IonSplitPane when="md" contentId="adminLayout">
        <IonMenu side="start" contentId="adminLayout">
          <IonHeader>
            <IonToolbar class="ion-padding-horizontal">
              <IonImg src="/assets/img/patientnow.svg" class="menu-logo" />
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              <IonMenuToggle autoHide={false}>
                <IonItem button routerLink="/admin/dashboard" className={location.pathname === "/admin/dashboard" ? 'selected' : undefined}>
                  <IonIcon slot="start" icon={barChartOutline} />
                  <IonLabel>Dashboard</IonLabel>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonItem button routerLink="/admin/scheduling" className={location.pathname === "/admin/scheduling" ? 'selected' : undefined}>
                  <IonIcon slot="start" icon={calendarOutline} />
                  <IonLabel>Scheduling</IonLabel>
                </IonItem>
              </IonMenuToggle>              
              <IonMenuToggle autoHide={false}>
                <IonItem button routerLink="/admin/patient-registration" className={location.pathname.startsWith("/admin/patient-registration") ? 'selected' : undefined}>
                    <IonIcon slot="start" icon={folderOpenOutline} />
                    <IonLabel>Patient Registration</IonLabel>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonItem button routerLink="/admin/clinical-information" className={location.pathname === "/admin/clinical-information" ? 'selected' : undefined}>
                    <IonIcon slot="start" icon={pulseOutline} />
                    <IonLabel>Clinical Information</IonLabel>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonItem button routerLink="/admin/patient-documents" className={location.pathname === "/admin/patient-documents" ? 'selected' : undefined}>
                    <IonIcon slot="start" icon={documentsOutline} />
                    <IonLabel>Patient Documents</IonLabel>
                </IonItem>
              </IonMenuToggle>               
            </IonList>
          </IonContent>
        </IonMenu>
        <IonRouterOutlet id="adminLayout">
          <Switch>
            <Route path="/admin/dashboard" component={Dashboard} exact />
            <Route path="/admin/scheduling" component={Scheduling} exact />
            <Route path="/admin/patient-registration" component={PatientRegistration} />            
            <Route path="/admin/clinical-information" component={ClinicalInformation} exact />
            <Route path="/admin/patient-documents" component={PatientDocuments} exact />
            <Route path="/admin/about" component={About} exact />
            <Route path="/admin/search-patient" component={SearchPatient} exact />
            <Redirect path="/admin" to="/admin/dashboard" />
          </Switch>
        </IonRouterOutlet>
      </IonSplitPane>
    </IonPage>
  );
};

export default Admin;
