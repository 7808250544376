import React from 'react';
import { IonLoading } from '@ionic/react';

const Loading: React.FC<{ isOpen : boolean; message?: string; }> = (props) => {
  return (
    <React.Fragment>
      <IonLoading
        isOpen={props.isOpen}
        //onDidDismiss={() => setShowLoading(false)}
        message={props.message ? props.message : "Please wait..."}
      />
    </React.Fragment>
  );
};

export default Loading;