import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import HeaderMenu from '../components/HeaderMenu';

const PatientInformation : React.FC = () => {
    return (
        <IonPage>
            <HeaderMenu title="Patient Information" />
            <IonContent class="ion-padding">
                <h1>Pat. Info page</h1>
            </IonContent>            
        </IonPage>
    );
}

export default PatientInformation;