import React, { useRef, useState } from "react";
import { useHistory } from "react-router";

import {
  IonAvatar,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSearchbar,
  IonSkeletonText,
  IonToolbar,
} from "@ionic/react";

import PatientsList from "../dummydata/PatientsList";
import Loading from "../components/Loading";

const SearchPatient: React.FC = () => {
  const history = useHistory();
  const refInfiniteScroll = useRef<HTMLIonInfiniteScrollElement>(null);
  const [searchValue, setSearchValue] = useState("");
  const [loadPatients, setLoadPatients] = useState(false);
  const [loading, setLoading] = useState<{ open: boolean, message?: string }>({ open: false, message: "" });

  const onSearchChange = (event: CustomEvent) => {
    setSearchValue(event.detail.value);
  };

  const patientClick = (patient: any) => {
    setLoading({ open: true });
    setTimeout(() => {
      setLoading({ open: false });
      console.log(patient);
      history.goBack();
    }, 600);
  };

  setTimeout(() => {
    setLoadPatients(true);
  }, 1000);

  const skeletonTextFields = [...Array(32)].map((e, i) => {
    return (
      <IonItem key={i}>
        <IonAvatar slot="start">
          <IonSkeletonText animated />
        </IonAvatar>
        <IonLabel>
          <h2>
            <IonSkeletonText animated style={{ width: "150px" }} />
          </h2>
          <p>
            <IonSkeletonText animated style={{ width: "100px" }} />
          </p>
        </IonLabel>
        <p>
          <IonSkeletonText slot="end" animated style={{ width: "60px" }} />
        </p>
      </IonItem>
    );
  });

  const loadMorePatiensData = () => {
    setTimeout(() => {
      refInfiniteScroll.current?.complete();
    }, 1500);
  }

  return (
    <IonPage>
      <Loading isOpen={loading.open} />
      <IonHeader class="ion-no-border ion-padding-horizontal">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/admin/dashboard" />
          </IonButtons>
          <IonSearchbar
            placeholder="Search Patient"
            inputmode="text"
            onIonChange={onSearchChange}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding-horizontal">
        <IonList>
          {loadPatients ?
          PatientsList.filter((patient) =>
            patient.patientname.toLowerCase().includes(searchValue)
          ).map((filteredPatient) => (
            <IonItem
              button
              key={filteredPatient.patientid}
              onClick={() => patientClick(filteredPatient)}
            >
              <IonAvatar slot="start">
                <img
                  src={filteredPatient.patientphoto}
                  alt={filteredPatient.patientname}
                />
              </IonAvatar>
              <IonLabel>
                <h2>{filteredPatient.patientname}</h2>
                <p>{filteredPatient.dob}</p>
              </IonLabel>
              <IonLabel slot="end" class="ion-text-end">
                <p>ID-{filteredPatient.patientid.padStart(4, "0")}</p>
              </IonLabel>
            </IonItem>
          )) : skeletonTextFields}
        </IonList>
        <IonInfiniteScroll threshold="100px" onIonInfinite={loadMorePatiensData} ref={refInfiniteScroll}>
            <IonInfiniteScrollContent loadingSpinner="crescent" loadingText="Loading more data..." />
        </IonInfiniteScroll>     
      </IonContent>
    </IonPage>
  );
};

export default SearchPatient;
