import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { 
    IonButton, 
    IonCard, 
    IonCardHeader, 
    IonCardSubtitle, 
    IonCol, 
    IonContent, 
    IonGrid,     
    IonIcon, 
    IonImg, 
    IonInput, 
    IonItem, 
    IonLabel,     
    IonPage, 
    IonRow, 
    IonText,     
    IonToggle    
} from '@ionic/react';

//Icons
import { lockClosedOutline, lockOpenOutline } from 'ionicons/icons';

//Images
import PatientNowLogo from '../assets/images/patientnow.svg';

//Styles
import '../assets/css/SignIn.css';
import Loading from '../components/Loading';

const SignIn: React.FC = () => {
    const history = useHistory();
    const [practice, setPractice] = useState<string>('patientnow_download');
    const [userName, setUserName] = useState<string>('admin');
    const [password, setPassword] = useState<string>('');    
    const [messageErrorPractice, setMessageErrorPractice] = useState<string>('');
    const [messageErrorUserName, setMessageErrorUserName] = useState<string>('');
    const [messageErrorPassword, setMessageErrorPassword] = useState<string>('');    
    const [openLock, setOpenLock] = useState<Boolean>(false);
    const [loading, setLoading] = useState<{ open: boolean, message?: string }>({ open: false, message: "" });

    const currentYear = new Date().getFullYear();

    const signInClick = () =>{ 
        let bool = true;
        if(practice===""){
            setMessageErrorPractice('Practice is required');
            bool = false;
        }else{
            setMessageErrorPractice('');
        }
        
        if(userName === ""){
            setMessageErrorUserName('User name is required');
            bool = false;
        }else{
            setMessageErrorUserName('');
        }
        
        if(password === ""){
            setMessageErrorPassword('Password is required');
            bool = false;
        }else{
            setMessageErrorPassword('');
        }
        
        if(bool){
            setLoading({open: true});
            setTimeout(() => {
                setLoading({open: false});
                history.push("/admin/dashboard");
            }, 1000);            
        }
    };

    const handleChangeLock = () =>{
        let bool = !openLock;
        setOpenLock( bool );
    };

    const onPasswordEnterKey = (e: React.KeyboardEvent) => {
        if(e.key === "Enter") signInClick();        
    }

    return (
        <IonPage>
            <Loading isOpen={loading.open} message={loading.message} />
            <IonContent >
                <IonGrid fixed className="ionHeightGridLogin">
                    <IonRow className="ion-justify-content-center ion-align-items-center ionHeightGridLogin">
                        <IonCol>
                            <IonGrid>
                                <IonRow>
                                    <IonCol sizeMd="8" offsetMd="2" sizeLg="6" offsetLg="3" sizeXl="4" offsetXl="4">
                                        <IonImg src={ PatientNowLogo } ></IonImg>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeMd="8" offsetMd="2" sizeLg="6" offsetLg="3" sizeXl="4" offsetXl="4" >
                                    <IonCard>
                                        <IonItem>
                                            <IonLabel position="floating"><p>Practice</p></IonLabel>
                                            <IonInput name="inputPractice" type="text" readonly={!openLock} value={ practice } onIonChange={ (e: any) => { setPractice(e.target.value) } } />
                                            <IonIcon icon={ !openLock ? lockClosedOutline : lockOpenOutline } onClick={ ()=>{ handleChangeLock() } } slot="end" />
                                            {
                                                messageErrorPractice !== '' ? <IonText color="danger" className="fontSizeError">{messageErrorPractice}</IonText> : ''
                                            }
                                        </IonItem>
                                        <IonItem>
                                                <IonLabel position="floating"><p>User Name</p></IonLabel>                            
                                                <IonInput type="text" value={ userName } onIonChange={ (e: any) => { setUserName(e.target.value) } } />
                                                {
                                                    messageErrorUserName !== '' ? <IonText color="danger" className="fontSizeError">{messageErrorUserName}</IonText> : ''
                                                }
                                        </IonItem>
                                        <IonItem>
                                                <IonLabel position="floating"><p>Password</p></IonLabel>                            
                                                <IonInput type="password" onIonChange={ (e: any) => { setPassword(e.target.value) } } onKeyDown= {onPasswordEnterKey} />
                                                {
                                                    messageErrorPassword !== '' ? <IonText color="danger" className="fontSizeError">{messageErrorPassword}</IonText> : ''
                                                }
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel slot="end">Remember me!</IonLabel><IonToggle color="primary" slot="end" />
                                        </IonItem>
                                        <IonButton expand="block" onClick={signInClick} >Login</IonButton>
                                        <IonCardHeader>
                                            <IonCardSubtitle>
                                                <span className="NoTextTransform">
                                                    Copyright &#169; {currentYear} PatientNOW Inc and PatientNOW LLC and its licensors. All rights reserverd.
                                                </span>
                                            </IonCardSubtitle>
                                            <IonCardSubtitle>
                                                <span className="NoTextTransform">
                                                    CPT&#174; only &#169; {currentYear} American Medical Association. All rights reserverd.
                                                </span>
                                            </IonCardSubtitle>
                                        </IonCardHeader>
                                        </IonCard>            
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default SignIn;