import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { ellipsisVertical, searchOutline } from "ionicons/icons";

import AccountPopOver from "./AccountPopOver";
import { useHistory } from "react-router";

const HeaderMenu: React.FC<{ title: string; translucent?: boolean }> = (props) => {
  const history = useHistory();
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({ open: false, event: undefined });

  const onAboutClick = () => {
    history.push("/admin/about");
  };

  const onSoftwareInformationClick = () => {
    history.push("/admin/software-information");
  }

  const onLogoutClick = () => {
    history.push("/auth");
  };

  const onDismissAccountPopOver = () => {
    setShowPopover({ open: false, event: undefined });
  };

  const showAccountPopOverClick = (event: React.MouseEvent) => {
    setShowPopover({ open: true, event: event.nativeEvent })
  }

  return (
    <React.Fragment>
      <AccountPopOver
        showPopover={showPopover}
        onAboutClick={onAboutClick}
        onSoftwareInformationClick={onSoftwareInformationClick}
        onLogoutClick={onLogoutClick}
        onDismiss={onDismissAccountPopOver}
      />
      <IonHeader translucent={props.translucent ? props.translucent : false}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{props.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink="/admin/search-patient">
              <IonIcon slot="icon-only" icon={searchOutline} />
            </IonButton>
            <IonButton onClick={showAccountPopOverClick} >
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    </React.Fragment>
  );
};

export default HeaderMenu;
