import React from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";

import HeaderMenu from "../components/HeaderMenu";

const About: React.FC = () => {
  return (
    <IonPage>
      <HeaderMenu title="About" translucent />
      <IonContent fullscreen>
        <IonGrid fixed>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonImg src="/assets/img/denver.jpg" />
                <IonCardHeader>
                  <IonCardSubtitle>Denver, Colorado</IonCardSubtitle>
                  <IonCardTitle>PatientNOW</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonLabel class="ion-text-justify">
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Pariatur, esse sunt dicta eius quae quia reprehenderit
                      mollitia velit eveniet. Omnis, reiciendis consectetur.
                      Sunt veniam deleniti, nostrum totam dolore aliquam
                      dolores. Lorem ipsum dolor sit amet consectetur
                      adipisicing elit. Atque placeat corrupti deserunt vero rem
                      ab distinctio unde harum voluptate nihil aperiam
                      perferendis iste tempora, veniam, dicta nostrum,
                      cupiditate quod provident. Lorem ipsum dolor, sit amet
                      consectetur adipisicing elit. Id doloribus praesentium
                      tenetur beatae reprehenderit! Illum nesciunt autem optio
                      consectetur labore, quibusdam dolore vitae accusamus,
                      ratione quasi recusandae fugit tempora quidem.
                    </p>
                    <p>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Nihil rerum blanditiis tenetur qui eum voluptate beatae,
                      iste in nobis soluta perspiciatis magnam dolores quas
                      earum quae possimus? Quia, aut amet.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Magnam eos aliquam nesciunt provident vel nisi minima in
                      temporibus sint modi fugiat accusantium doloremque placeat
                      rem, quam autem dolores corrupti dolore. Lorem ipsum
                      dolor, sit amet consectetur adipisicing elit. Beatae
                      aliquid nesciunt ut corrupti numquam aliquam magnam
                      mollitia expedita excepturi, maiores assumenda iste sunt
                      optio quos deleniti voluptas harum nam fuga!. Lorem ipsum
                      dolor sit amet consectetur adipisicing elit. Eaque
                      perferendis quo animi alias, cumque dolorum pariatur
                      numquam officiis quidem ut adipisci nobis suscipit aliquam
                      fugit sit ipsum optio dolor ipsa?. Lorem ipsum dolor sit
                      amet consectetur adipisicing elit. Assumenda adipisci
                      magni itaque rerum quas placeat, impedit praesentium
                      voluptas dicta unde asperiores necessitatibus minus hic
                      ipsa autem alias, eligendi optio illum.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Aut dicta perferendis soluta praesentium numquam. Minima
                      nobis harum modi doloribus aliquid. Cumque enim nobis eos
                      error nisi magnam repellendus alias natus.
                    </p>
                  </IonLabel>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default About;
