const PatientsList = [
    {
        "patientphoto": "https://reqres.in/img/faces/1-image.jpg",
        "patientname": "Bluth, George",
        "patientid": "1",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",			
    },
    {
        "patientphoto": "https://reqres.in/img/faces/2-image.jpg",
        "patientname": "Weaver, Janet",
        "patientid": "2",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",			
    },
    {
        "patientphoto": "https://reqres.in/img/faces/3-image.jpg",
        "patientname": "Wong, Emma",
        "patientid": "3",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
        
    },
    {
        "patientphoto": "https://reqres.in/img/faces/4-image.jpg",
        "patientname": "Holt, Eve",
        "patientid": "4",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",			
    },
    {
        "patientphoto": "https://reqres.in/img/faces/5-image.jpg",
        "patientname": "Morris, Charles",
        "patientid": "5",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",			
    },
    {
        "patientphoto": "https://reqres.in/img/faces/6-image.jpg",
        "patientname": "Ramos, Tracey",
        "patientid": "6",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "https://reqres.in/img/faces/7-image.jpg",
        "patientname": "Lawson, Michael",
        "patientid": "7",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "https://reqres.in/img/faces/8-image.jpg",
        "patientname": "Ferguson, Lindsay",
        "patientid": "8",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "https://reqres.in/img/faces/9-image.jpg",
        "patientname": "Funke, Tobias",
        "patientid": "9",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",			
    },
    {
        "patientphoto": "https://reqres.in/img/faces/10-image.jpg",
        "patientname": "Fields, Byron",
        "patientid": "10",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",			
    },
    {
        "patientphoto": "https://reqres.in/img/faces/11-image.jpg",
        "patientname": "Edwars, George",
        "patientid": "11",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",			
    },
    {
        "patientphoto": "https://reqres.in/img/faces/12-image.jpg",
        "patientname": "Howel, Rachel",
        "patientid": "12",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",			
    },    
    {
        "patientphoto": "/assets/img/profile-pic-l.jpg",
        "patientname": "Herbert, Anna",
        "patientid": "1000",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-2.jpg",
        "patientname": "Coucher, Sam",
        "patientid": "1001",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",        
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-3.jpg",
        "patientname": "Duggan, Cornelius",
        "patientid": "1002",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-4.jpg",
        "patientname": "Galaktenoff, Katherine",
        "patientid": "1003",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-5.jpg",
        "patientname": "Hubbard, Sarah",
        "patientid": "1004",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-6.jpg",
        "patientname": "Dog, The",
        "patientid": "1005",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-7.jpg",
        "patientname": "Katlayak, Nicolia",
        "patientid": "1006",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-8.jpg",
        "patientname": "Kerkosoff, John",
        "patientid": "1007",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-9.jpg",
        "patientname": "Kizangutuy, Pete",
        "patientid": "1008",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-10.jpg",
        "patientname": "Nuytak, Katia",
        "patientid": "1009",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-11.jpg",
        "patientname": "Pearce, Chas",
        "patientid": "1010",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l.jpg",
        "patientname": "Robertson, Alma",
        "patientid": "1011",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-2.jpg",
        "patientname": "Rockfeller, Gladstone",
        "patientid": "1012",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-3.jpg",
        "patientname": "Rodin, Will",
        "patientid": "1013",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-4.jpg",
        "patientname": "Samanoff, Sanga",
        "patientid": "1014",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-5.jpg",
        "patientname": "Wasky, Slena",
        "patientid": "1015",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-6.jpg",
        "patientname": "Dog, Comic",
        "patientid": "1016",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-7.jpg",
        "patientname": "Wiley, Jesse",
        "patientid": "1017",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-8.jpg",
        "patientname": "Young, James",
        "patientid": "1018",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    },
    {
        "patientphoto": "/assets/img/profile-pic-l-9.jpg",
        "patientname": "Perolin, Valentin",
        "patientid": "1019",
        "dob": "03/02/1986",
        "ssn": "01-845",
        "chartid": "000001",
    }
]

export default PatientsList;