import React, { useRef, useState } from "react";
import {
  IonPopover,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonToggle,
} from "@ionic/react";
import {
  codeSlashOutline,
  informationCircleOutline,
  logOutOutline,
  moonOutline,
} from "ionicons/icons";

import Loading from "./Loading";
import SoftwareInformationModal from "../pages/SoftwareInformationModal";

const AccountPopOver: React.FC<{
  showPopover: { open: boolean; event: Event | undefined };
  onAboutClick: () => void;
  onLogoutClick: () => void;
  onSoftwareInformationClick: () => void;
  onDismiss: () => void;
}> = (props) => {
  const refIonPopOver = useRef<HTMLIonPopoverElement>(null);
  const [loading, setLoading] = useState<{ open: boolean, message?: string }>({ open: false, message: "" });
  const [showSoftwareInformation, setShowSoftwareInformation] = useState(false);

  const aboutClick = () => {
    setLoading({ open: true });
    refIonPopOver.current?.dismiss().then(() => {
      setLoading({ open: false });
      props.onAboutClick();
    });
  };

  const logoutClick = () => {
    setLoading({ open: true, message: "Loggin out..." });
    refIonPopOver.current?.dismiss().then(() => {
      setLoading({ open: false });
      props.onLogoutClick();
    });
  };

  const softwareInformationClick = () => {
    setLoading({ open: true });
    refIonPopOver.current?.dismiss().then(() => {
      setLoading({ open: false });
      setShowSoftwareInformation(true);
    });
  };

  const onDismisssSoftwareInformation = () => {
    setShowSoftwareInformation(false);
  };

  const onDismiss = () => {
    props.onDismiss();
  };

  const toggleDarkModeHandler = () => {
    document.body.classList.toggle("dark");    
    //document.body.classList.add("dark");
    //document.body.classList.remove("dark");
    console.log(document.body.classList.contains("dark"));
  } 

  return (
    <React.Fragment>
      <Loading isOpen={loading.open} message={loading.message} />
      <SoftwareInformationModal
        show={showSoftwareInformation}
        onDismiss={onDismisssSoftwareInformation}
      />
      <IonPopover
        isOpen={props.showPopover.open}
        event={props.showPopover.event}
        onDidDismiss={onDismiss}
        ref={refIonPopOver}
      >
        <IonList>
          <IonItem>
            <IonIcon slot="start" icon={moonOutline} />
            <IonLabel>Dark Mode</IonLabel>
            <IonToggle
              slot="end"
              name="darkMode"
              onIonChange={toggleDarkModeHandler}
            />
          </IonItem>
          <IonItem button onClick={aboutClick}>
            <IonIcon slot="start" icon={informationCircleOutline} />
            <IonLabel>About</IonLabel>
          </IonItem>
          <IonItem button onClick={softwareInformationClick}>
            <IonIcon slot="start" icon={codeSlashOutline} />
            <IonLabel>Software Information</IonLabel>
          </IonItem>
          <IonItem button onClick={logoutClick}>
            <IonIcon slot="start" icon={logOutOutline} />
            <IonLabel>Logout</IonLabel>
          </IonItem>
        </IonList>
      </IonPopover>
    </React.Fragment>
  );
};

export default AccountPopOver;
