import React from "react";
import { IonContent, IonPage } from "@ionic/react";

import HeaderMenu from "../components/HeaderMenu";

const PatientDocuments: React.FC = () => {
  return (
    <IonPage>
      <HeaderMenu title="Patient Documents" />
      <IonContent class="ion-padding">
        <h1>PatientDocuments page</h1>
      </IonContent>
    </IonPage>
  );
};

export default PatientDocuments;
