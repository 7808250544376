import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import HeaderMenu from '../components/HeaderMenu';

const ContactInformation : React.FC = () => {
    return (
        <IonPage>
            <HeaderMenu title="Contact information" />
            <IonContent class="ion-padding">
                <h1>Contact Info page</h1>
            </IonContent>            
        </IonPage>
    );
}

export default ContactInformation;