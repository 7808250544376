import React, { useRef } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

const SoftwareInformationModal: React.FC<{
  show: boolean;
  onDismiss: () => void;
}> = (props) => {

  const refElement = useRef<HTMLIonModalElement>(null);

  const closeClick = () => {
    refElement.current?.dismiss();
  };

  return (
    <IonModal
      isOpen={props.show}
      onDidDismiss={props.onDismiss}
      ref={refElement}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={closeClick}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Software Information</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem lines="full">
            <IonLabel>
              <p>Company</p>
              <h2>Patientnow Download Aesthetics</h2>
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel>
              <p>License</p>
              <h2>Medical Records Studio 6.0</h2>
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel>
              <p>TextNOW</p>
              <h2>(720) 439-3681</h2>
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel>
              <p>Version</p>
              <h2>7.0.203.1</h2>
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel class="ion-text-wrap ion-text-justify">
              <p>
                Copyright © {new Date().getFullYear()} PatientNOW Inc and
                PatientNOW LLC and its licensors. All rights reserved.
              </p>
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel class="ion-text-wrap ion-text-justify">
              <p>
                Warning: This computer program is protected by copyright law and
                international treaties. Unauthorized reproduction or
                distribution of this program, or any portion of it, may result
                in severe civil and criminal penalties, and will be prosecuted
                to the maximum extent possible under the law. By installing,
                copying, or otherwise using this computer program, the user
                agrees to be bound by the terms and conditions of this license
                agreement. This computer program is the property of PatientNOW,
                Inc. The user may not disassemble, de compile, or modify any
                part of the application or its associated files. The user may
                not sell, rent, lend or otherwise distribute this computer
                program on a stand alone basis or as part of a collection, or as
                a part of any product or service for resale unless you have
                previously formed a license agreement with Patient|NOW, Inc.
                This computer program is supplied with NO WARRANTIES.
                Patient|NOW, Inc. expressly disclaims any warranty for the
                computer program. This computer program and related documents
                are provided "AS IS"; without warranty of any kind, expressed or
                implied. This includes implied warranties of fitness for a
                particular purpose. All risk arising out of use of this software
                remains with the user. By using this computer program, the user
                agrees that Patient|NOW, Inc. shall not be responsible or liable
                for damages whatsoever. This includes, without limitation,
                damages for loss of business profits, loss due to business
                interruption, loss of business information, or any other
                pecuniary loss, arising out of the use of or the inability to
                use this computer software. CPT® only © 2018 American Medical
                Association. All rights reserved. Fee schedules, relative value
                units, conversion factors and/or related components are not
                assigned by the AMA, are not part of CPT®, and the AMA is not
                recommending their use. The AMA does not directly or indirectly
                practice medicine or dispense medical services. The AMA assumes
                no liability for data contained or not contained herein. CPT is
                a registered trademark of the American Medical Association.
              </p>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default SoftwareInformationModal;
