import React from "react";
import { Route, Redirect, Switch } from "react-router";
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { callOutline, documentOutline, pencilOutline } from "ionicons/icons";

import PatientInformation from "./PatientInformation";
import ContactInformation from "./ContactInformation";
import ConsentInformation from './ConsentInformation';

const PatientRegistration: React.FC = () => {
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Switch>
          <Route path="/admin/patient-registration/patient-information" component={PatientInformation} exact />
          <Route path="/admin/patient-registration/contact-information" component={ContactInformation} exact />
          <Route path="/admin/patient-registration/consent-information" component={ConsentInformation} exact />
          <Redirect path="/admin/patient-registration" to="/admin/patient-registration/patient-information" />
        </Switch>
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="patientInformation" href="/admin/patient-registration/patient-information">
          <IonIcon icon={documentOutline} />
          <IonLabel>Patient Info.</IonLabel>
        </IonTabButton>
        <IonTabButton tab="contactInformation" href="/admin/patient-registration/contact-information">
          <IonIcon icon={callOutline} />
          <IonLabel>Contact Info.</IonLabel>
        </IonTabButton>
        <IonTabButton tab="consentInformation" href="/admin/patient-registration/consent-information">
          <IonIcon icon={pencilOutline} />
          <IonLabel>Consent Info.</IonLabel>
        </IonTabButton>        
      </IonTabBar>
    </IonTabs>
  );
};

export default PatientRegistration;
